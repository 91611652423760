<template>
    <div class="grid-attachment-view flex-column">
        <div class="grid-attachment-view__attachment-list" v-if="attachmentUrls.length && !loadingAttachments">
            <template v-for="(file, i) in imageAttachments" v-bind:key="i">
                <el-image
                fit="cover"
                :src="file.src"
                class="grid-attachment-view__image" :preview-src-list="previewImages">
                <el-icon><Picture /></el-icon>
                </el-image>
            </template>
            <template v-for="(file, i) in documentAttachments" v-bind:key="i">
              <template v-if="!isImage(file)">
                <a v-if="isPdfFile(file.originalName)" :href="file.url" title="View Pdf" target="_blank" class="document-icon-wrapper" style="widht: 60px; height: 60px;">
                    <img class="pdf-icon" src="@/assets/icons/pdf-document-icon.svg" style="width: 100%; height: 100%;"/>
                </a>
                <a v-else :href="file.url" title="View File" target="_blank" class="document-icon-wrapper">
                    <el-icon><Document /></el-icon>
                </a>
              </template>
            </template>
        </div>
        <div class="attachment-list margin-top-24" v-if="loadingAttachments">
          <i class="common-icon-loading"></i>
        </div>

        <div v-if="!attachmentUrls.length && !loadingAttachments">
          <p>No files found.</p>
        </div>

        <!-- <ActionItemComponent
          label="Download all"
          icon="download-icon.svg"
          fontColor="#FFA500"
          fontSize="14px"
          @call-to-action="downloadAllImages"/> -->
    </div>
</template>
<script>
import {
  Document, Picture
} from '@element-plus/icons';
import { defineComponent } from 'vue';

// import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import { isImageAttachment, isPdfFile } from '@/core/helpers/file.helper';

export default defineComponent({
  components: {
    Document,
    Picture,
    // ActionItemComponent
  },

  props: ['attachmentUrls', 'documentAttachments', 'imageAttachments', 'loadingAttachments'],

  computed: {
    previewImages() {
      return this.imageAttachments.map((attachment) => attachment.src);
    },
  },

  methods: {
    isImage(file) {
      return isImageAttachment(file);
    },

    isPdfFile(originalName) {
      return isPdfFile(originalName);
    }
  },
});
</script>
<style lang="scss" scoped>
.grid-attachment-view {
    &__attachment-list {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        gap: .5rem;
    }
    &__image {
        width: 100% !important;
        border-radius: 8px;
        height: 100%;
        max-height: 90px;
        min-height: 90px;
        object-fit: cover;
    }
}
</style>

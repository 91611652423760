<template>
    <div class="footer-call-to-action flex-row ai-c jc-sb">
        <slot></slot>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>
<style lang="scss" scoped>
.footer-call-to-action {
    padding: .5rem 1.5rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: rgba(12, 15, 74, 0.10);
}
.el-divider {
    margin: 0;
    height: 80%;
    width: 2px;
}
</style>

<template>
  <div class="thumbnail-counter">
    <UserThumbnail
      class="thumbnail-counter__thumbnail"
      v-for="(member, index) in visibleMembers"
      :key="index"
      borderRadius="100%"
      width="22px"
      height="22px"
      :source="member?.toUser?.userPublicProfile?.profileImage"
      ></UserThumbnail>
    <div class="thumbnail-counter__counter flex-row ai-c jc-c" v-if="remainingMembersCount > 0">
      <p class="thumbnail-counter__text default-text">+{{ remainingMembersCount }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';

export default defineComponent({
  components: {
    UserThumbnail
  },
  props: ['members'],
  computed: {
    visibleMembers() {
      return this.members.slice(0, 3);
    },
    remainingMembersCount() {
      return Math.max(0, this.members.length - 3);
    }
  }
});
</script>

<style lang="scss" scoped>
  .thumbnail-counter {
    display: flex;
    position: relative;
    width: auto;

    &__thumbnail {
      height: 100%;
      width: 100%;
      margin-left: -8px;
    }
    &__counter {
      text-align: center;
      background-color: #FFF;
      width: 22px;
      height: 22px;
      color: #fff;
      border-radius: 100%;
      min-width: 22px;
      min-height: 22px;
      margin-left: -8px;
      position: relative;
      z-index: 1;
    }
    &__text {
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px; /* 312.5% */
        letter-spacing: 0.25px;
    }
  }
</style>

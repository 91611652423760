<template>
    <div class="counter flex-row ai-c jc-c">
      <span class="counter__item default-text">{{ counter }}</span>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    counter: {
      type: String,
      default: '0'
    }
  }
});
</script>
<style lang="scss" scoped>
.counter {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: #FFA500;

  &__item {
    font-size: 10px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #FFF;
  }
}
</style>
